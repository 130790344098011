@import "~bootswatch/dist/cosmo/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/cosmo/bootswatch";

@import "variables";

body {
  background-image: url("/bg-light.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;

  &.wrapper-full-page {
    min-height: 100vh;
    height: auto;
  }
}

.main-panel {
  position: relative;
  float: right;
  width: $sidebar-width;
  background-color: rgba(255, 255, 255, 0.8);

  .navbar {
    .avatar {
      display: inline-block;
      width: 30px;
      height: 30px;
      vertical-align: middle;
      margin-right: 10px;
      margin-top: -10px;
      margin-bottom: -10px;
    }
  }
}

.page-content {
  padding: 10px;
}

.pagination {
  justify-content: flex-end;
}

.toaster {
  position: absolute;
  top: 20px;
  right: 20px;

  &.success {
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.error {
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}

.block {
  background: #fcfcfc;
  padding: 10px;
  border: 1px solid #dee2e6;
  margin-bottom: 15px;
  border-radius: 5px;

  h2 {
    margin-bottom: 25px;
  }
}

.fileInput {
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.main-panel button svg {
  margin-right: 4px;
}

.link {
  cursor: pointer;
}

.actions a,
td a {
  color: #373a3c;
  text-decoration: underline;
}

.actions {
  .link {
    span {
      margin-right: 0.5rem;
    }
  }
}

.lastLogin {
  display: block;
  position: absolute;
  right: 12px;
  margin-top: 11px;
  font-style: italic;
  font-size: 13px;
}

.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.btns {
  input[type="button"],
  input[type="submit"],
  button {
    padding: 6px 50px;
  }

  input[type="submit"],
  .btn-primary {
    margin-left: 10px;
  }
}

.modal-80w {
  max-width: 80%;
}

.clickable {
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
}

.orderable {
  cursor: pointer;
}
.ordering {
  padding: 3px;
  margin-left: 15px;
  display: inline-block;
}

.pageSize {
  display: inline-block;
  > div {
    z-index: 100;
    display: inline-block;
    width: 80px;
  }
}

.sidebar-collapsed {
  .sidebar {
    width: 50px;

    .logo {
      padding: 0px;
      padding-left: 8px;
      font-size: 27px;
      text-align: center;

      span {
        display: none;
      }

      img {
        display: none;
      }
    }

    .welcome {
      display: none;
    }

    .card-header {
      padding-left: 13px !important;
      height: 100%;

      button {
        text-align: center;
        padding: 0;
      }

      span {
        display: none;
      }

      .icon {
        font-size: 20px;
      }
    }

    .menu.accordion {
      width: 100%;
    }

    .logout {
      right: auto;
      left: 3px;
    }

    .sidebar-wrapper,
    .sidebar-scroll,
    .flex-column,
    .menu.accordion,
    .card {
      position: initial;
    }

    .card {
      height: 49px;
    }

    .collapse {
      position: absolute;
      min-width: 140px;
      left: 49px;
      background: #215370;

      .card-body {
        padding: 0px 23px;
        display: none;

        &.hover {
          display: block !important;
        }
      }
    }
  }

  .main-panel {
    width: calc(100% - 50px);
  }
}

.sidebar {
  .logo {
    text-align: center;
    a {
      color: inherit;
      text-decoration: none;
    }

    img {
      width: 220px;
    }
  }

  #burger {
    position: absolute;
    right: -50px;
    top: 8px;

    button {
      background: #215370;
    }
  }

  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 260px;
  left: 0;
  z-index: 1030;
  border-right: 1px solid #ddd;
  background-color: #215370;
  color: hsla(0, 0%, 100%, 0.65);

  display: flex;
  flex-direction: column;

  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    overflow: auto;
    width: 100%;
    z-index: 4;
    padding-bottom: 60px;

    .dropdown .dropdown-backdrop {
      display: none !important;
    }

    .navbar-form {
      border: none;
    }

    .card {
      background: none;
      font-weight: 600px;
      font-size: 15px;

      &:hover {
        background: rgba(7, 9, 25, 0.15);
        color: white;
      }
    }

    .btn-link {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }

    .arrow-right,
    .arrow-down {
      float: right;
      font-size: 16px;
    }

    .sidebar-scroll {
      height: 100%;
      overflow: scroll;

      // hide scrollbars
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .logout {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 5;
  }

  .navbar-minimize {
    position: absolute;
    right: 20px;
    top: 2px;
    opacity: 1;
  }

  .logo {
    position: relative;
    padding: 7px 0.7rem;
    z-index: 4;
  }

  .logo span {
    font-size: 26px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-left: 15px;
  }

  .welcome {
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 30px;

    p {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .menu {
    .btn {
      text-align: left;
    }

    a {
      display: block;
      margin-top: 5px;
      margin-bottom: 5px;
      color: hsla(0, 0%, 100%, 0.65);
    }

    .card-header {
      padding: 5px;

      button {
        font-weight: 600;
        font-size: 16px;
      }
    }

    .card-body {
      padding: 0px;
      padding-left: 40px;

      a {
        &:hover {
          text-decoration: none;
          color: white;
          font-weight: 600;
        }
      }
    }

    button {
      width: 100%;
    }

    .icon {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

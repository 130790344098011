.autocompleteField {
    &>div {
        &>div {
            z-index: 100;
        }

        width:100%;
    }

    .dropdown {
        padding: 5px;
        cursor: pointer;
    }

    input {
        width: 100% !important;
        padding: 0.375rem 0.75rem;
        border: 1px solid #ced4da;
        margin-bottom: 5px;
    }
}

.autocomplete-items {
    padding: 8px 12px;
    border-radius: 3px;
    cursor: pointer;
}
// Sidebar variables
$sidebar-width: calc(100% - 260px) !default;
$sidebar-mini-width: calc(100% - 80px) !default;

@mixin toast-variant($background, $border, $color) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;

  hr {
    border-top-color: darken($border, 5%);
  }

  .toast-link {
    color: darken($color, 10%);
  }
}

$toast-bg-level: -10 !default;
$toast-border-level: -9 !default;
$toast-color-level: 6 !default;

@each $color, $value in $theme-colors {
  .toast-#{$color} {
    @include toast-variant(
      theme-color-level($color, $toast-bg-level),
      theme-color-level($color, $toast-border-level),
      theme-color-level($color, $toast-color-level)
    );
  }
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container input {
  width: 100%;
  border-radius: 0.3rem !important;
  font-family: "Segoe UI", "Source Sans Pro", Calibri, Candara, Arial,
    sans-serif;
  padding-top: 7px;
  padding-right: 8px;
  padding-bottom: 7px;
  padding-left: 8px;
  background-color: #fff;
  outline-color: rgb(38, 132, 255);
  border: 1px solid rgb(204, 204, 204);
}

.react-datepicker-popper {
  min-width: 330px !important;
}

.page-content .block > .block {
  background: #f9f9f9;
  border-radius: 0px;
}

.block-grey {
  background: #f7f7f7;
}

h3 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 350;
}

h4 {
  background: rgba(0, 0, 0, 0.1);
  margin: -10px -10px 10px -10px;
  border-radius: 4px 4px 0px 0px;
  padding: 10px 20px;
  font-size: 20px;
}

.select-container.is-invalid {
  border: 1px solid red;
  border-radius: 4px;

  div > div {
    border: 0px;
  }
}
